<template>
  <main class="evaluation-statistics">
    <el-tabs v-model="changeActiveName" @tab-click="handleClick" class="statistics-header">
      <el-tab-pane label="按员工" name="changeStaff">
        <EvaluateStaff />
      </el-tab-pane>
      <el-tab-pane label="按门店" name="changeStore">
        <EvaluateStore />
      </el-tab-pane>
    </el-tabs>
  </main>
</template>

<script>
import EvaluateStaff from './EvaluateStaff.vue';
import EvaluateStore from './EvaluateStore.vue';

export default {
  components: {
    EvaluateStaff,
    EvaluateStore,
  },
  data() {
    return {
      changeActiveName: 'changeStaff',
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluation-statistics {
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 0 20px;

  .statistics-header {
    padding: 10px 0;

    .staff-header {
      padding: 10px 0;
    }
  }
}
</style>
