<template>
  <main class="evaluate-staff">
    <header class="staff-header">
      <!-- 搜索 表单 -->
      <el-form :inline="true" :model="search" label-width="95px">
        <el-form-item label="员工Id:" label-width="58px">
          <el-input @input="(value) => (search.userId = value.replace(/[^0-9.]/g, ''))" v-model="search.userId" placeholder="请输入员工Id"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名:">
          <el-input v-model="search.userName" placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item label="岗位:">
          <el-select v-model="search.postId" placeholder="请选择岗位" style="width: 100%">
            <el-option v-for="item in postOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="sourceFormEnum == 1" label="门店名称:">
          <el-input v-model="search.storeName" placeholder="请输入门店名称"></el-input>
        </el-form-item>
        <el-form-item v-if="sourceFormEnum == 1" label="所属经营方:">
          <el-input v-model="search.firmName" placeholder="请输入经营方名称"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button icon="el-icon-search" type="primary" @click="searchList">查询</el-button>
          <el-button icon="el-icon-refresh-right" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>

      <!-- 时间选择 -->
      <el-form :inline="true">
        <el-form-item>
          <el-button class="operate-item" v-for="(item, i) in timeList" :class="i === timeIndex ? 'operate-item-checked' : ''" :key="i" @click="calcDate(i)">{{ item }}</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-date-picker
            v-model="dateArr"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changeTime(dateArr)"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item>
          <el-button icon="el-icon-upload2" @click="handleExport()" v-loading.fullscreen.lock="fullscreenLoading">导出</el-button>
        </el-form-item>
      </el-form>
    </header>

    <!-- 表格- -->
    <el-table
      :data="tableData"
      style="width: 100%"
      class="table-ex"
      border
      :header-cell-style="{ 'background-color': '#f5f7fa' }"
      size="small"
      @sort-change="sortChange"
      ref="Table"
      v-loading="loadingTable"
    >
      <el-table-column type="index" label="序号" fixed="left"></el-table-column>
      <el-table-column prop="userId" label="员工" fixed="left">
        <template slot-scope="scope">
          {{ scope.row.userName }}
          <span class="jian" v-if="scope.row.isFullTime === 0">兼</span>
          <div class="table-ex-div" v-if="scope.row.userId">ID:{{ scope.row.userId }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="postName" label="岗位">
        <template slot-scope="scope">
          <div class="table-post">
            {{ scope.row.postName }}
            <img v-if="scope.row.isMainPost === 1" class="zhu" :src="require('@/assets/zhu@2x.png')" alt="" />
          </div>
        </template>
      </el-table-column>

      <el-table-column v-if="sourceFormEnum == 1" prop="storeName" label="门店名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="tooltip-content" v-if="scope.row.isResident === 1">常驻</span>
          <span class="tooltip-content" v-if="scope.row.isResident === 2">关联</span>
          {{ scope.row.storeName }}
        </template>
      </el-table-column>

      <el-table-column prop="firmName" label="所属经营方" :show-overflow-tooltip="true"></el-table-column>

      <el-table-column prop="baseProductTypeName" label="服务类型"></el-table-column>

      <el-table-column prop="shootNum">
        <template slot="header">
          <div class="table-psl">
            <span>拍摄量</span>
            <el-tooltip placement="top">
              <i class="el-icon-warning-outline"></i>
              <div slot="content">
                <span>开始服务的服务单数量</span>
              </div>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <span :class="scope.row.shootNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'shootServiceIds')">
            {{ scope.row.shootNum }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="finishServiceNum" label="完成服务单量">
        <template slot-scope="scope">
          <span :class="scope.row.finishServiceNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'finishServiceIds')">
            {{ scope.row.finishServiceNum }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="serviceNum" label="已评价数量" sortable="custom" width="110">
        <template slot-scope="scope">
          <span :class="scope.row.serviceNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'sumEvaServiceIds')">
            {{ scope.row.serviceNum }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="defaultEvaNum" label="默认评价数量">
        <template slot-scope="scope">
          <span :class="scope.row.defaultEvaNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'defaultEvaServiceIds')">
            {{ scope.row.defaultEvaNum }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="userEvaNum" label="用户评价数量">
        <template slot-scope="scope">
          <span :class="scope.row.userEvaNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'userEvaServiceIds')">
            {{ scope.row.userEvaNum }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="indoorsTotalNum" label="内景"></el-table-column>
      <el-table-column prop="outdoorsTotalNum" label="外景"></el-table-column>
      <el-table-column prop="fiveStarConversion" label="5星数量" sortable="custom" fixed="right">
        <template slot-scope="scope">
          <el-tooltip placement="top-start">
            <div slot="content">
              换算数量：{{ Number(scope.row.fiveStarConversion).toFixed(2) }}
              <br />
              实际数量：{{ Number(scope.row.fiveStarActualNum) }}(内景：{{ Number(scope.row.fiveStarIndoorsNum) }}；外景：{{ Number(scope.row.fiveStarOutdoorsNum) }})
            </div>
            <div class="table-ex-td">
              {{ Number(scope.row.fiveStarConversion).toFixed(2) }}
              <span class="table-ex-span">[{{ Number(scope.row.fiveStarActualNum) }}]</span>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="fourStarConversion" label="4星数量" sortable="custom" fixed="right">
        <template slot-scope="scope">
          <el-tooltip placement="top-start">
            <div slot="content">
              换算数量：{{ Number(scope.row.fourStarConversion).toFixed(2) }}
              <br />
              实际数量：{{ Number(scope.row.fourStarActualNum) }}(内景：{{ Number(scope.row.fourStarIndoorsNum) }}；外景：{{ Number(scope.row.fourStarOutdoorsNum) }})
            </div>
            <div class="table-ex-td">
              {{ Number(scope.row.fourStarConversion).toFixed(2) }}
              <span class="table-ex-span">[{{ Number(scope.row.fourStarActualNum) }}]</span>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="threeStarConversion" width="130" label="3星及以下数量" sortable="custom" fixed="right">
        <template slot-scope="scope">
          <el-tooltip placement="top-start">
            <div slot="content">
              换算数量：{{ Number(scope.row.threeStarConversion).toFixed(2) }}
              <br />
              实际数量：{{ Number(scope.row.threeStarActualNum) }}(内景：{{ Number(scope.row.threeStarIndoorsNum) }}；外景：{{ Number(scope.row.threeStarOutdoorsNum) }})
            </div>
            <div class="table-ex-td">
              {{ Number(scope.row.threeStarConversion).toFixed(2) }}
              <span class="table-ex-span">[{{ Number(scope.row.threeStarActualNum) }}]</span>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <!--  翻页 -->
    <el-pagination
      class="footer"
      background
      layout="total, prev, pager, next, sizes, jumper"
      :total="total"
      :page-sizes="[10, 50, 100, 200]"
      @size-change="pageSizeChange"
      @current-change="pageChange"
      :current-page="pageNumber"
      :page-size="pageSize"
    ></el-pagination>
  </main>
</template>

<script>
import { getEvaluationStatistics, getPostList, getCountServiceItemAndEvaluationExport } from '@/api/newEvaluation';
import { getSource } from '@/utils';

export default {
  data() {
    return {
      timeList: ['近7天', '近30天', '近1年', '本月'],
      timeIndex: 3,
      search: {
        sortField: '', // 排序字段
        sort: '', // 升/降序
        userId: '', // 员工Id
        userName: '', // 员工姓名
        postId: '', // 岗位Id
        storeId: '', // 门店Id
        storeName: '', //  门店名称
        firmName: '', // 所属经营方名称
        export: 2,
      },
      pageNumber: 1,
      pageSize: 10,
      tableData: [],
      total: 0,
      postOptions: [],
      dateArr: [],
      loadingTable: true,
      sourceFormEnum: getSource().sourceFormEnum,
      fullscreenLoading: false,
    };
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search };
      if (this.dateArr && this.dateArr.length > 1) {
        obj['startTime'] = this.dateArr ? this.dateArr[0] : '';
        obj['endTime'] = this.dateArr ? this.dateArr[1] : '';
      }
      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
    },
    showStoreSearch() {
      return this.$route.query.showStoreSearch == 'true';
    },
  },
  mounted() {
    this.getPosts();
    this.calcDate(3);
  },
  methods: {
    // 获取岗位
    getPosts() {
      getPostList({ data: {} })
        .then((res) => {
          this.postOptions = (res.data || []).map((el) => ({
            label: el.postName,
            value: el.postId,
          }));
        })
        .catch((err) => console.log(err));
    },
    // 获取表格数据
    async getList() {
      try {
        this.loadingTable = true;
        this.search.startTime = this.dateArr ? this.dateArr[0] : '' || this.search.startTime;
        this.search.endTime = this.dateArr ? this.dateArr[1] : '' || this.search.endTime;
        const data = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          data: {
            ...this.search,
          },
        };
        const res = await getEvaluationStatistics(data);
        if (res.data) {
          const { content, total } = res.data;
          this.total = +total;
          this.tableData = content;
          this.loadingTable = false;
        }
      } catch (error) {
        console.log(error);
        this.loadingTable = false;
      } finally {
        this.loadingTable = false;
      }
    },
    // 表格-排序
    sortChange({ prop, order }) {
      this.search.sortField = prop;
      this.search.sort = order == 'descending' ? 'DESC' : 'ASC';
      this.getList();
    },
    // 查询
    searchList() {
      this.pageNumber = 1;
      this.getList();
      // this.getListTime()
    },
    // 重置
    resetSearch() {
      this.$refs['Table'] && this.$refs['Table'].clearSort();
      Object.keys(this.search).forEach((el) => {
        this.search[el] = Array.isArray(this.search[el]) ? [] : '';
      });
      this.pageNumber = 1;
      this.pageSize = 10;
      this.timeIndex = 3;
      this.calcDate(3);
    },
    // 翻页
    pageSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.pageNumber = val;
      this.getList();
    },
    // 时间选择--近7天...
    calcDate(index) {
      this.pageNumber = 1;
      this.timeIndex = index;
      if (index == 0) {
        //近7天
        const currentDate = new Date();
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        this.dateArr = [sevenDaysAgo, currentDate];
      }
      if (index == 1) {
        //近30天
        const currentDate = new Date();
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
        this.dateArr = [thirtyDaysAgo, currentDate];
      }
      if (index == 2) {
        //近1年
        const currentDate = new Date();
        const oneYearAgo = new Date(currentDate);
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
        this.dateArr = [oneYearAgo, currentDate];
      }
      if (index == 3) {
        // 本月
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const startDate = new Date(year, month - 1, 1);
        this.dateArr = [startDate, currentDate];
      }
      this.dateArr[0] = this.formatDateToYYYYMMDDHHMMSSks(this.dateArr[0]);
      this.dateArr[1] = this.formatDateToYYYYMMDDHHMMSSjs(this.dateArr[1]);
      this.getListTime();
    },
    formatDateToYYYYMMDDHHMMSSks(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      // const hours = String(date.getHours()).padStart(2, '0');
      // const minutes = String(date.getMinutes()).padStart(2, '0');
      // const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${'00'}:${'00'}:${'00'}`;
    },
    formatDateToYYYYMMDDHHMMSSjs(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day} ${'23'}:${'59'}:${'59'}`;
    },
    // 改变时间
    changeTime(vals) {
      if (!vals || vals == null) {
        const reSetTime = new Date();
        this.reSetTime = this.formatDateToYYYYMMDDHHMMSSjs(reSetTime);
        this.search.startTime = '2020-06-20 00:00:00';
        this.search.endTime = this.reSetTime;
      }
      this.timeIndex = '';
      this.pageNumber = 1;
      this.getList();
    },
    getListTime() {
      getEvaluationStatistics(this.searchCondition)
        .then((res) => {
          const { content, total } = res.data || {};
          this.tableData = content || [];
          this.total = Number(total || '0');
          this.pageNumber = 1;
          this.loadingTable = false;
        })
        .catch((err) => console.log(err));
    },
    // 导出
    async handleExport() {
      // let data = JSON.parse(JSON.stringify(this.search));
      this.fullscreenLoading = true;
      this.search.export = 1;
      this.dateArr = this.dateArr || [];
      const currentDateNew = new Date();
      this.currentDateNew = this.formatDateToYYYYMMDDHHMMSSjs(currentDateNew);
      this.search.startTime = this.dateArr[0] || '2020-06-20 00:00:00';
      this.search.endTime = this.dateArr[1] || this.currentDateNew;
      const newdata = {
        data: {
          ...this.search,
        },
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      getCountServiceItemAndEvaluationExport(newdata)
        .then((res) => {
          const link = document.createElement('a');
          link.style.display = 'none';
          link.target = 'view_window';
          link.href = URL.createObjectURL(res);
          link.download = '员工评价表.xlsx'; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    // 跳转服务订单列表
    gotoServiceOrderDetail(row, type) {
      const serviceIds = JSON.stringify(row[type]);
      localStorage.setItem('serviceIds', serviceIds);
      if (row[type] && row[type].length > 0) {
        if (this.sourceFormEnum === 1) {
          const newUrl = `${window.location.origin}/index/service/serviceOrder`;
          window.open(newUrl);
        } else {
          const newUrl = `${window.location.origin}/index/supplyorder/list`;
          window.open(newUrl);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scope-router {
  cursor: pointer;
  color: #2861e2;
  padding: 0 10px 10px 0;
}
.scope-router-no {
  cursor: default;
  color: #b5b6b6;
}
.evaluate-staff {
  .staff-header {
    padding: 10px 0 0 0;

    .el-form-item {
      margin-right: 0;
    }

    .operate-item {
      padding: 7px 10px;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      color: #000000;
      font-size: 14px;
    }

    .operate-item:hover {
      cursor: pointer;
      background-color: #2861e2;
      border: 1px solid #2861e2;
      color: #fff;
    }

    .operate-item-checked {
      background-color: #2861e2;
      border: 1px solid #2861e2;
      color: #fff;
    }
  }

  .table-ex {
    margin-top: 4px;
    .table-psl {
      display: flex;
      align-items: center;
      > i {
        margin-left: 2px;
        font-size: 14px;
        vertical-align: middle;
      }
    }
    .jian {
      width: 14px;
      height: 14px;
      background: rgba(248, 223, 161, 0.2);
      border-radius: 1px 1px 1px 1px;
      border: 1px solid #b1a996;
      font-size: 12px;
      font-weight: 400;
      color: #726a56;
      display: inline-block;
      text-align: center;
      line-height: 1;
      box-sizing: border-box;
    }
    .table-post {
      display: flex;
      align-items: center;
      .zhu {
        width: 14px;
        height: 14px;
        margin-left: 2px;
      }
    }

    .table-ex-div {
      font-size: 12px;
      font-weight: 400;
      color: #9a9d9e;
      line-height: 1;
    }

    .table-ex-td {
      cursor: default;
      display: inline;
    }

    .table-ex-span {
      color: #b5b6b6;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }
}

.tooltip-content {
  width: 26px;
  height: 14px;
  background: rgba(91, 72, 223, 0.05);
  border-radius: 1px 1px 1px 1px;
  border: 1px solid #b2aae9;
  font-size: 12px;
  color: #5b48df;
  line-height: 14px;
  text-align: center;
  display: inline-block;
}
</style>
