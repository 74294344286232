<template>
  <main class="evaluate-store">
    <header class="staff-header">
      <!-- 搜索表单 -->
      <el-form :inline="true" :model="search" v-if="sourceFormEnum == 1" label-width="95px">
        <el-form-item label="门店Id:" label-width="58px">
          <el-input @input="(value) => (search.storeId = value.replace(/[^0-9.]/g, ''))" v-model="search.storeId" placeholder="请输入门店Id"></el-input>
        </el-form-item>
        <el-form-item label="门店名称:">
          <el-input v-model="search.storeName" placeholder="请输入门店名称"></el-input>
        </el-form-item>
        <el-form-item label="所属经营方:">
          <el-input v-model="search.firmName" placeholder="请输入经营方名称"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button icon="el-icon-search" type="primary" @click="searchList">查询</el-button>
          <el-button icon="el-icon-refresh-right" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>

      <!-- 时间选择 -->
      <el-form :inline="true">
        <el-form-item>
          <el-button class="operate-item" v-for="(item, i) in timeList" :class="i === timeIndex ? 'operate-item-checked' : ''" :key="i" @click="calcDate(i)">{{ item }}</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-date-picker
            v-model="dateArr"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changeTimes(dateArr)"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item>
          <el-button icon="el-icon-upload2" @click="handleExport()" v-loading.fullscreen.lock="fullscreenLoading">导出</el-button>
        </el-form-item>
      </el-form>
    </header>

    <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      class="table-ex"
      border
      :header-cell-style="{ 'background-color': '#f5f7fa' }"
      size="small"
      @sort-change="sortChange"
      ref="Table"
      v-loading="loadingTable"
    >
      <el-table-column v-if="sourceFormEnum == 1" type="index" label="序号" fixed="left"></el-table-column>
      <el-table-column v-if="sourceFormEnum == 1" prop="storeId" label="门店" fixed="left" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.storeName }}
          <div class="table-ex-div" v-if="scope.row.storeId">ID:{{ scope.row.storeId }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="sourceFormEnum == 1" width="100" prop="firmName" label="所属经营方" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.firmName }}
          <div class="table-ex-div" v-if="scope.row.firmId">ID:{{ scope.row.firmId }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="shootNum" width="80">
        <template slot="header">
          <div class="table-psl">
            <span>拍摄量</span>
            <el-tooltip placement="top">
              <i class="el-icon-warning-outline"></i>
              <div slot="content">
                <span>开始服务的服务单数量</span>
              </div>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <span :class="scope.row.shootNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'shootServiceIds')">
            {{ scope.row.shootNum }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="finishServiceNum" label="完成服务单量">
        <template slot-scope="scope">
          <span :class="scope.row.finishServiceNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'finishServiceIds')">
            {{ scope.row.finishServiceNum }}
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="sourceFormEnum == 1" prop="serviceNum" label="已评价数量" sortable="custom">
        <template slot-scope="scope">
          <span :class="scope.row.serviceNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'sumEvaServiceIds')">
            {{ scope.row.serviceNum }}
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="sourceFormEnum == 4" prop="serviceNum" label="已评价数量">
        <template slot-scope="scope">
          <span :class="scope.row.serviceNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'sumEvaServiceIds')">
            {{ scope.row.serviceNum }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="defaultEvaNum" label="默认评价数量">
        <template slot-scope="scope">
          <span :class="scope.row.defaultEvaNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'defaultEvaServiceIds')">
            {{ scope.row.defaultEvaNum }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="userEvaNum" label="用户评价数量">
        <template slot-scope="scope">
          <span :class="scope.row.userEvaNum === 0 ? 'scope-router-no' : 'scope-router'" @click.stop="gotoServiceOrderDetail(scope.row, 'userEvaServiceIds')">
            {{ scope.row.userEvaNum }}
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="sourceFormEnum == 1" width="120" prop="environment5StarNum" label="环境5星数量" sortable="custom"></el-table-column>
      <el-table-column v-if="sourceFormEnum == 4" prop="environment5StarNum" label="环境5星数量"></el-table-column>

      <el-table-column v-if="sourceFormEnum == 1" width="120" prop="environment4StarNum" label="环境4星数量" sortable="custom"></el-table-column>
      <el-table-column v-if="sourceFormEnum == 4" prop="environment4StarNum" label="环境4星数量"></el-table-column>

      <el-table-column v-if="sourceFormEnum == 1" width="150" prop="environment3StarNum" label="环境3星及以下数量" sortable="custom"></el-table-column>
      <el-table-column v-if="sourceFormEnum == 4" prop="environment3StarNum" label="环境3星及以下数量"></el-table-column>

      <el-table-column v-if="sourceFormEnum == 1" width="120" prop="serve5StarNum" label="服务5星数量" sortable="custom"></el-table-column>
      <el-table-column v-if="sourceFormEnum == 4" prop="serve5StarNum" label="服务5星数量"></el-table-column>

      <el-table-column v-if="sourceFormEnum == 1" width="120" prop="serve4StarNum" label="服务4星数量" sortable="custom"></el-table-column>
      <el-table-column v-if="sourceFormEnum == 4" prop="serve4StarNum" label="服务4星数量"></el-table-column>

      <el-table-column v-if="sourceFormEnum == 1" width="150" prop="serve3StarNum" label="服务3星及以下数量" sortable="custom"></el-table-column>
      <el-table-column v-if="sourceFormEnum == 4" prop="serve3StarNum" label="服务3星及以下数量"></el-table-column>
    </el-table>

    <!-- 翻页 -->
    <el-pagination
      v-if="sourceFormEnum == 1"
      class="footer"
      background
      layout="total, prev, pager, next, sizes, jumper"
      :total="total"
      :page-sizes="[10, 50, 100, 200]"
      @size-change="pageSizeChange"
      @current-change="pageChange"
      :current-page="pageNumber"
      :page-size="pageSize"
    ></el-pagination>
  </main>
</template>

<script>
import { getStoreEvaluationPageList, getStoreEvaluationPageListExport } from '@/api/newEvaluation';
import { getSource } from '@/utils';

export default {
  data() {
    return {
      timeList: ['近7天', '近30天', '近1年', '本月'],
      timeIndex: 3,
      search: {
        storeId: '', // 门店Id
        storeName: '', // 门店名称
        firmName: '', // 所属经营方名称
        createTimeStart: '',
        createTimeEnd: '',
        queryTimeType: '',
        storeIds: [],
        sortField: '', // 排序字段
        sort: '', // 升降序
        export: 2,
      },
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      postOptions: [],
      dateArr: [],
      loadingTable: true,
      sourceFormEnum: getSource().sourceFormEnum,
      fullscreenLoading: false,
    };
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search };
      if (this.dateArr && this.dateArr.length > 1) {
        obj['createTimeStart'] = this.dateArr ? this.dateArr[0] : '';
        obj['createTimeEnd'] = this.dateArr ? this.dateArr[1] : '';
      }
      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
    },
    showStoreSearch() {
      return this.$route.query.showStoreSearch == 'true';
    },
  },
  // watch: {
  //   dateArr(val) {
  //     if (!val) {
  //       this.calcDate(3);
  //       this.$nextTick(() => {
  //         this.timeIndex = 3;
  //       })
  //       return;
  //     }
  //   }
  // },
  mounted() {
    // this.getList();
    this.calcDate(3);
  },
  methods: {
    //  获取表格数据
    async getList() {
      try {
        this.loadingTable = true;
        this.search.createTimeStart = this.dateArr ? this.dateArr[0] : '' || this.search.createTimeStart;
        this.search.createTimeEnd = this.dateArr ? this.dateArr[1] : '' || this.search.createTimeEnd;
        const data = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          data: {
            ...this.search,
          },
        };
        const res = await getStoreEvaluationPageList(data);
        if (res.data) {
          const { content, total } = res.data;
          this.total = +total;
          this.tableData = content;
          this.loadingTable = false;
        }
      } catch (error) {
        console.log(error);
        this.loadingTable = false;
      } finally {
        this.loadingTable = false;
      }
    },
    // 表格-排序
    sortChange({ prop, order }) {
      this.search.sortField = prop;
      this.search.sort = order == 'descending' ? 'DESC' : 'ASC';
      this.getList();
    },
    // 查询
    searchList() {
      this.pageNumber = 1;
      this.getList();
      // this.getListTime()
    },
    // 重置
    resetSearch() {
      this.$refs['Table'] && this.$refs['Table'].clearSort();
      Object.keys(this.search).forEach((el) => {
        this.search[el] = Array.isArray(this.search[el]) ? [] : '';
      });
      this.pageNumber = 1;
      this.pageSize = 10;
      this.timeIndex = 3;
      this.calcDate(3);
    },
    // 翻页
    pageSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.pageNumber = val;
      this.getList();
    },
    // 时间选择
    calcDate(index) {
      this.pageNumber = 1;
      this.timeIndex = index;
      if (index == 0) {
        //近7天
        const currentDate = new Date();
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        this.dateArr = [sevenDaysAgo, currentDate];
      }
      if (index == 1) {
        //近30天
        const currentDate = new Date();
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
        this.dateArr = [thirtyDaysAgo, currentDate];
      }
      if (index == 2) {
        //近1年
        const currentDate = new Date();
        const oneYearAgo = new Date(currentDate);
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
        this.dateArr = [oneYearAgo, currentDate];
      }
      if (index == 3) {
        // 本月
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const startDate = new Date(year, month - 1, 1);
        this.dateArr = [startDate, currentDate];
      }
      this.dateArr[0] = this.formatDateToYYYYMMDDHHMMSSks(this.dateArr[0]);
      this.dateArr[1] = this.formatDateToYYYYMMDDHHMMSSjs(this.dateArr[1]);
      this.getListTime();
    },
    formatDateToYYYYMMDDHHMMSSks(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      // const hours = String(date.getHours()).padStart(2, '0');
      // const minutes = String(date.getMinutes()).padStart(2, '0');
      // const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${'00'}:${'00'}:${'00'}`;
    },
    formatDateToYYYYMMDDHHMMSSjs(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day} ${'23'}:${'59'}:${'59'}`;
    },
    changeTimes(vals) {
      if (!vals || vals == null) {
        const reSetTime = new Date();
        this.reSetTime = this.formatDateToYYYYMMDDHHMMSSjs(reSetTime);
        this.search.createTimeStart = '2020-06-20 00:00:00';
        this.search.createTimeEnd = this.reSetTime;
      }
      this.timeIndex = '';
      this.pageNumber = 1;
      this.getList();
    },
    getListTime() {
      getStoreEvaluationPageList(this.searchCondition)
        .then((res) => {
          const { content, total } = res.data || {};
          this.tableData = content || [];
          this.total = Number(total || '0');
          this.pageNumber = 1;
          this.loadingTable = false;
        })
        .catch((err) => console.log(err));
    },
    // 导出1
    async handleExport() {
      // let data = JSON.parse(JSON.stringify(this.search));
      this.fullscreenLoading = true;
      this.search.export = 1;
      this.dateArr = this.dateArr || [];
      const currentDateNew = new Date();
      this.currentDateNew = this.formatDateToYYYYMMDDHHMMSSjs(currentDateNew);
      this.search.createTimeStart = this.dateArr[0] || '2020-06-20 00:00:00';
      this.search.createTimeEnd = this.dateArr[1] || this.currentDateNew;
      const newdata = {
        data: {
          ...this.search,
        },
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      getStoreEvaluationPageListExport(newdata)
        .then((res) => {
          const link = document.createElement('a');
          link.style.display = 'none';
          link.target = 'view_window';
          link.href = URL.createObjectURL(res);
          link.download = '门店评价表.xlsx'; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
    // 跳转服务订单列表--> 1-运营端 4-供应商端 7-服务端
    gotoServiceOrderDetail(row, type) {
      const serviceIds = JSON.stringify(row[type]);
      localStorage.setItem('serviceIds', serviceIds);
      if (row[type] && row[type].length > 0) {
        if (this.sourceFormEnum === 1) {
          const newUrl = `${window.location.origin}/index/service/serviceOrder`;
          window.open(newUrl);
        } else {
          const newUrl = `${window.location.origin}/index/supplyorder/list`;
          window.open(newUrl);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scope-router {
  cursor: pointer;
  color: #2861e2;
  padding: 0 10px 10px 0;
}
.scope-router-no {
  cursor: default;
  color: #b5b6b6;
}
.evaluate-store {
  .staff-header {
    padding: 10px 0 0 0;

    .el-form-item {
      margin-right: 0;
    }

    .operate-item {
      padding: 7px 10px;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      color: #000000;
      font-size: 14px;
    }

    .operate-item:hover {
      cursor: pointer;
      background-color: #2861e2;
      border: 1px solid #2861e2;
      color: #fff;
    }

    .operate-item-checked {
      background-color: #2861e2;
      border: 1px solid #2861e2;
      color: #fff;
    }
  }

  .table-ex {
    margin-top: 4px;
    .table-psl {
      display: flex;
      align-items: center;
      > i {
        margin-left: 2px;
        font-size: 14px;
        vertical-align: middle;
      }
    }
    .table-ex-div {
      font-size: 12px;
      font-weight: 400;
      color: #9a9d9e;
      line-height: 1;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }
}
</style>
